@keyframes slideUp {
    from {
      transform: translateY(100%);
    }
  
    to {
      transform: translateY(0%);
    }
  }

@keyframes slideDown {
    from {
        transform: translateY(0%);
    }

    to {
        transform: translateY(100%);
    }   
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0%);
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(100%);
    }
}

@keyframes  popIn {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1.0);
    }
}

@keyframes popOut {
    from {
        transform: scale(1.0);
    }

    to { 
        transform: scale(0);
    }
}