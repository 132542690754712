.settings-container {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: #FFFFFF;

    display: flex;
    flex-direction: row;

    z-index: 2;
}

.settings-container.opening {
    animation: slideUp 200ms ease-in;
}

.settings-container.closing{
    animation: slideDown 200ms ease-out;
}

.settings-side-bar {
    width: calc(25% - 1px);
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    font-family: Poppins;
    letter-spacing: 0.1em;


    border-right: 1px solid black;
} .settings-side-bar .options {
    height: 55%;
    width: 100%;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.options:hover{
    background-color: #ACABAB;
}

.tab-selection {
    text-decoration-line: underline;
    box-shadow: inset -8px 0 0px 0px #FF6868;
}

.settings-content {
    width: 75%;
    height: 100%;
    display: flex;
}

.settings-exit-wrapper {
    position: absolute;
    top: 6px;
    right: 16px;

    height: 30px;
    width: 30px;

    cursor: pointer;
}

.settings-exit {
    height: 100%;
    width: 100%;
}