:root {
    --triangleColor: unset;
    --squareColor: unset;
    --circleColor: unset;

    --shapeButtonColor: unset;
    --shapeButtonHover: unset;
    --shapeButtonSelected: unset;

    --animationButtonColor: unset;
    --animationButtonHover: unset;
    --animationButtonSelected: unset;
}

.shape-toolbar-container {
    height: 75%;
    width: 15%;
    
    background-color: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.shape-selection {
    height: 30%;
    width: 100%;

    display: flex;
    justify-content: center;

    background-color: var(--shapeButtonColor);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 0px;

    cursor: pointer;
} 

.shape-selection:hover {
    background-color: var(--shapeButtonHover);
}

.shape-selected {
    background-color: var(--shapeButtonSelected);
}

.shape-icon {
    width: 80%;
    max-height: 80%;
    display: block;
    margin: auto;
}

.triangle {
    fill: var(--triangleColor);
}

.square {
    fill: var(--squareColor);
}

.circle {
    fill: var(--circleColor);
}

.fill-div {
    height: 100%;
    width: 100%;
}