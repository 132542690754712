@import "../AnimationToolbar/AnimationToolbar.css";

.settings-content{
    width: 100%;
}

.animation-customization-container {
    width: 100%;
}

.all-animations-container {
    width: 100%;
    height: 75%;
    overflow-y: scroll;
}

.selected-animations-container {
    width: calc(100% - 17px);
    height: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.selected-animation {
    height: 100%;
    width: 30%;
    display: flex;
    background-color: var(--animationButtonColor);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px 10px 0px 0px;
    cursor: pointer;
    margin-bottom: 0;

    font-size: 5vw;
    letter-spacing: 0.1em;
    color: white;
    -webkit-text-stroke: 2px black;
    text-align: right;
}

.selected-animation span{
    margin-right: 10px;
    margin-top: -20px;
}

.unused-animation-row {
    height: 40%;
    display: flex;
    justify-content: center;
}

.unused-animation {
    width: 100%;
    background-color: var(--animationButtonColor);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    cursor: pointer;
    margin: 30px;
    vertical-align: center;
    position: relative;
    font-weight: 600;
    
    font-size: 3.5vw;
    letter-spacing: 0.1em;
    color: white;
    -webkit-text-stroke: 2px black;
    text-align: right;
}

.unused-animation img{
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.unused-animation span{
    margin-right: 8px;
    margin-top: -15px;
}

.selected-spacer {
    height: 10px;
    width: 100%;
    box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.25);
}

.unused-animation.animation-selected {
    background-color: var(--animationButtonSelected);
}
