.color-picker-container {
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.chrome-picker {
    height: auto;
    min-width: 90%;
    max-height: 80% ;
    display: block;
}

.reset-container {
    height: 3.5vw;
    width: 55%;
    margin-top: 5%;

}
.reset-color-button {
    height: 100%;
    width: 100%;
    color: black;
    background-color: #ACABAB;
    border-radius: 5px 5px 5px 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    
    font-family: Poppins;
    font-weight: 500;
    font-size: 1.8vw;
    letter-spacing: 0.05em;

    cursor: pointer;

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
} .reset-color-button:hover {
    background-color: #767676;
}

.buttons-container {
    height: 100%;
    width: 60%;

    display: flex;
    flex-wrap: wrap;
}

.buttons-row {
    height: 50%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 20px 0%; 
} .one {
    align-items: flex-end;
} .two {
    align-items: flex-start;
}

.shape-icon-wrapper {
    width: 25%;
    height: 11.25vw; /* .75*.60*.25: Height == Width*/
}

.shape-icon-settings {
    width: 100%;
    height: 100%;
    cursor: pointer;
} 

.shape-button, .animation-button{
    height: 10vw;
    width: 40%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 600;
    font-size: 2.8vw;
    letter-spacing: 0.1em;
    color: white;
    -webkit-text-stroke: 1px black;
    
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 0px;

    cursor: pointer;
}

.shape-button {
    background-color: var(--shapeButtonColor);
}

.animation-button {
    background-color: var(--animationButtonColor);
}