.animation-toolbar-container {
    height: 25%;
    width: 85%;

    background-color: none;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.animation-selection {
    height: 100%;
    width: 30%;

    display: flex;
    justify-content: center;

    background-color: var(--animationButtonColor);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px 10px 0px 0px;

    cursor: pointer;
}

.animation-selection:hover {
    background-color: var(--animationButtonHover);
}

.animation-selected {
    background-color: var(--animationButtonSelected);
}

.animation-icon {
    height: auto;
    max-width: 80%;
    max-height: 80%;
    display: block;
    margin: auto;
}