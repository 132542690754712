.options-container {
    float: top;

    height: 25%;
    width: 15%;
    
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
}

.wrapper {
    width: 30%;
    height: 30%;
    margin: 1px;
}

.help-wrapper {
    height: 31%;
    width: 100%
}

.help-icon {
    width: 74%;
    max-height: 100%;
    margin-left: 10%;
    margin-right: 10%;
    cursor: pointer;
    border-radius: 10px;
}

.icon {
    width: 80%;
    cursor: pointer;
}

.icon:hover {
    background-color: rgb(160, 160, 160, 0.5);
}

@font-face {
    font-family: Poppins;
    src: url(../../Poppins-Regular.ttf);
}

.title {
    font-family: Poppins;
    position: absolute;
    bottom: 4px;
    left: 10px;

    font-weight: 400;
    /* font-size: 30px; */
    font-size: 1.8vw;
    letter-spacing: 0.25em;
}