.canvas-container {
    height: 75%;
    width: 85%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.p5Canvas {
    position: relative;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.hawkeyeGrid {
    position: absolute;
    height: calc(75% - 40px);
    width: calc(85% - 40px);
    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    align-items: center;
    z-index: 2;
}

.hawkeyeCell {
    display: block;
    height: 20%;
    width: 10%;
    /* background-color:  rgba(37, 70, 255, 0.2); Remove when finished */
}