.canvas-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    z-index: 1;
}

#msg {
    margin-top: 50%;
    margin-bottom: 50%;
    padding-left: 2%;
    padding-right: 2%;
    text-align: center;
}